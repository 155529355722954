import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Nav, Dropdown, Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [currentYear, setCurrentYear] = useState(2020);
  const [subhead, setSubhead] = useState("");
  const [books, setBooks] = useState([]);
  const [columns, setColumns] = useState({});
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortType, setSortType] = useState("title");
  const [selType, setSelType] = useState("All");
  const [library, setLibrary] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selSubject, setSelSubject] = useState('Subject');

  // const screenWidth = window.innerWidth/1.1;
  // console.log(screenWidth)
  // below goes in react Table element:
  // style={{minWidth: screenWidth + "px"}}

  function parseGSFeed(entries, docType, cost = 'Free', headerRow = 2) {
    let columns = {};
    let mySubjects = [];
    let books = [];
    let book = {};
    let subIndex = 0;
    book['Type'] = docType;
    book['Cost'] = cost;
    //let curRow = "";
    var i;
    var item;
    //curRow = headerRow+1+"";
    for (i = 0; i < entries.length; i++) {
      item = entries[i];
      if(i === 1) {
        columns = item;
        subIndex = columns.indexOf("Subject")
      } else if(i > 1) {
        book = {};
        book['Type'] = docType;
        book['Cost'] = cost;
        book['Link'] = item[6];
        book[columns[0]] = item[0];
        book[columns[1]] = item[1];
        book[columns[2]] = item[2];
        book[columns[3]] = item[3];
        book[columns[4]] = item[4];
        book[columns[5]] = item[5];
        books.push(book);
        if(!mySubjects.includes(item[subIndex])){
          mySubjects.push(item[subIndex])
        }
      }
      if(typeof book['Subject'] === 'undefined') {
        book['Subject'] = ''
      }

      // if(parseInt(item['gs$cell']['row']) > headerRow){
      //   if(curRow !== item['gs$cell']['row']) {
      //     books.push(book);
      //     curRow = item['gs$cell']['row'];
      //     book = {};
      //     book['Type'] = docType;
      //     book['Cost'] = cost;
      //   }
      //   book[columns[item['gs$cell']['col']]] = item['gs$cell']['$t']
      //   if(i+1 === entries.length) {
      //     books.push(book);
      //   }
      //   if(columns[item['gs$cell']['col']] === "Subject") {
      //     if(!mySubjects.includes(item['gs$cell']['$t'])){
      //       mySubjects.push(item['gs$cell']['$t']);
      //     }
      //   }
      // } else if(parseInt(item['gs$cell']['row']) === headerRow) {
      //   columns[item['gs$cell']['col']] = item['gs$cell']['$t'];
      // }
    }
    console.log(columns);
    console.log(cost);
    console.log(docType);
    console.log(mySubjects);
    return [columns,books,mySubjects];
  }

  useEffect(() => {
    async function onLoad() {
      let d = new Date();
      setCurrentYear(d.getYear()+1900);
      let parsed = [];
      let parsed2 = [];
      let parsed3 = [];
      let payload = "";
      let myBooks = [];
      //let mySubjects = [];
      try {
        //let response = await fetch("https://spreadsheets.google.com/feeds/cells/1a_7mnVdGSIKtLihiPBa-gh06BxkxHeGzH8nghcb4Ooc/1/public/values?alt=json-in-script");
        let response = await fetch("https://sheets.googleapis.com/v4/spreadsheets/1a_7mnVdGSIKtLihiPBa-gh06BxkxHeGzH8nghcb4Ooc/values/In%20Library?alt=json&key=AIzaSyAWEFywJ9NX0ovo1LyeLG3FFYpj1pAmn4c");
        console.log(response);
        if (response.ok) { 
          payload = await response.text();
          console.log(payload);
          // response is not properly formatted JSON, need to trim front and back
          //payload = payload.substring(payload.search("{"))
          //payload = payload.substring(0, payload.length - 2);
          payload = JSON.parse(payload);
          console.log(payload.values);
          //console.log(payload)
          // parse the response
          //parsed = parseGSFeed(payload.feed.entry, "Book")
          parsed = parseGSFeed(payload.values, "Book")
          //setSubhead(payload.feed.entry[0]['gs$cell']['$t']);
          setSubhead(payload.values[0][0]);
          //console.log(parsed[0]);
          setColumns(parsed[0]);
          //mySubjects = parsed[2];
          //console.log(parsed[1]);
          //setBooks(parsed[1]);
        }

        //response = await fetch("https://spreadsheets.google.com/feeds/cells/1a_7mnVdGSIKtLihiPBa-gh06BxkxHeGzH8nghcb4Ooc/2/public/values?alt=json-in-script");
        response = await fetch("https://sheets.googleapis.com/v4/spreadsheets/1a_7mnVdGSIKtLihiPBa-gh06BxkxHeGzH8nghcb4Ooc/values/Not%20in%20Library?alt=json&key=AIzaSyAWEFywJ9NX0ovo1LyeLG3FFYpj1pAmn4c");
        if (response.ok) { 
          payload = await response.text();
          // response is not properly formatted JSON, need to trim front and back
          //payload = payload.substring(payload.search("{"))
          //payload = payload.substring(0, payload.length - 2);
          payload = JSON.parse(payload);
          //console.log(payload)
          // parse the response
          parsed2 = parseGSFeed(payload.values, "Book", 'Paid')
          //console.log(parsed2)
        } 

        //response = await fetch("https://spreadsheets.google.com/feeds/cells/1RzC7DBE9bSNG9c64QxFoj38SPJEDLQ5I2gzXwC-HWDY/1/public/values?alt=json-in-script");
        response = await fetch("https://sheets.googleapis.com/v4/spreadsheets/1RzC7DBE9bSNG9c64QxFoj38SPJEDLQ5I2gzXwC-HWDY/values/Journals%20%26%20slides?alt=json&key=AIzaSyAWEFywJ9NX0ovo1LyeLG3FFYpj1pAmn4c");
        if (response.ok) { 
          payload = await response.text();
          // response is not properly formatted JSON, need to trim front and back
          //payload = payload.substring(payload.search("{"))
          //payload = payload.substring(0, payload.length - 2);
          payload = JSON.parse(payload);
          // parse the response
          parsed3 = parseGSFeed(payload.values, "Paper")
        }
        myBooks = parsed[1].concat(parsed2[1], parsed3[1])
        //mySubjects = new Set(parsed[2].concat(parsed2[1], parsed3[2]))
        //setSubjects(Array.from(mySubjects).sort());
        var myLibrary = sortBooks(myBooks, parsed[0], sortType, sortDirection)
        setBooks(myLibrary);
        setLibrary(myLibrary);
        const unique = [...new Set(myLibrary.map(item => item.Subject))];
        setSubjects(unique.sort());
        //console.log(mySubjects.sort());
        //setBooks(parsed[1].concat(parsed2[1]))

      } catch (e) {
        alert(e);
      }
    }
    onLoad();
  }, []);

  function changeSortType(sel) {
    setSortType(sel);
    setBooks(sortBooks(books, columns, sel, sortDirection));
  }

  function changeSortDirection(sel) {
    setSortDirection(sel);
    setBooks(sortBooks(books, columns, sortType, sel));
  }

  function filterType(sel) {
    var myBooks = [];
    //console.log(sel)
    setSelType(sel);
    if(sel === 'All'){
      if(selSubject === 'Subject'){
        // get all entries
        myBooks = sortBooks(library, columns, sortType, sortDirection)
        setBooks(myBooks);
      } else {
        // get all types of the selected subject
        myBooks = sortBooks(library.filter(function (obj) { return obj.Subject.includes(selSubject); }), columns, sortType, sortDirection)
        setBooks(myBooks);
      }
      const unique = [...new Set(library.map(item => item.Subject))];
      //console.log(unique);
      setSubjects(unique.sort());
    } else {
      if(selSubject === 'Subject'){
        // get selected type of all subjects
        myBooks = sortBooks(library.filter(function (obj) { return obj.Type.includes(sel); }), columns, sortType, sortDirection)
        setBooks(myBooks);
      } else {
        // get selected type of selected subject
        var types = []
        myBooks = library.filter(function (obj) { return obj.Type.includes(sel); })
        types = sortBooks(myBooks.filter(function (obj) { return obj.Subject.includes(selSubject); }), columns, sortType, sortDirection)
        setBooks(types);
      }
      const unique = [...new Set(myBooks.map(item => item.Subject))];
      //console.log(unique);
      setSubjects(unique.sort());
    }
  }

  function filterSubject(sel) {
    setSelSubject(sel);
    //console.log(sel);
    console.log(library);
    if(sel === 'Subject'){
      if(selType === 'All'){
        // get all entries
        setBooks(sortBooks(library, columns, sortType, sortDirection));
      } else {
        // get selected type of all subjects
        setBooks(sortBooks(library.filter(function (obj) { return obj.Type.includes(selType); }), columns, sortType, sortDirection));
      }
    } else {
      if(selType === 'All'){
        // get all types of selected subject
        setBooks(sortBooks(library.filter(function (obj) { return obj.Subject.includes(sel); }), columns, sortType, sortDirection));
      } else {
        // get selected type of selected subject
        var subjects = []
        subjects = library.filter(function (obj) { return obj.Subject.includes(sel); })
        setBooks(sortBooks(subjects.filter(function (obj) { return obj.Type.includes(selType); }), columns, sortType, sortDirection));
      }
    }
  }

  function sortBooks(sortBooks, sortColumns, sortType, sortDirection) {
    let myBooks;
    let col;
    let dir;
    if(sortType === "title") {
      col = 0;
    } else if(sortType === 'author') {
      col = 1;
    } else if(sortType === 'subject') {
      col = 5;
    }
    if(sortDirection === "asc")  {
      dir = 1;
    } else {
      dir = -1;
    }
    //console.log(sortBooks);
    myBooks = sortBooks;
    myBooks.sort((a, b) => {
      if (a[sortColumns[col]] < b[sortColumns[col]])
        return -dir;
      if (a[sortColumns[col]] > b[sortColumns[col]])
        return dir;
      return 0;
    })
    //console.log(myBooks);
    //setBooks(myBooks);
    return myBooks
  }

  return (
    <Container fluid className="text-center">
      <Row className="mt-4">
        <Col>
          <h1>The Library of the Serpent</h1>
          <p className="lead">{subhead}</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={8} className="tile-filters"> 
          <Nav
           variant="pills"
           defaultActiveKey="title"
           onSelect={changeSortType}
          >
            <Nav.Item>
              <Nav.Link eventKey="disabled" disabled>Sort by</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="title">title</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="author">author</Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-none d-sm-table-cell">
              <Nav.Link eventKey="subject">subject</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs={4} className="tile-filters"> 
          <Nav 
           className="justify-content-end"
           variant="pills"
           defaultActiveKey="asc"
           onSelect={changeSortDirection}
          >
            {["asc","desc"].map((item, index) => 
              <Nav.Item key={index}>
                <Nav.Link eventKey={item}>{item}</Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={8} className="tile-filters"> 
          <Nav
           variant="pills"
           defaultActiveKey="All"
           onSelect={filterType}
          >
            <Nav.Item>
              <Nav.Link eventKey="disabled" disabled>Show</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="All">all</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Book">books</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Paper">papers</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs={4} className="text-right">
          {subjects.length > 0 
            ? <Dropdown onSelect={filterSubject} onClick={e => e.stopPropagation()}>
                <Dropdown.Toggle className="drop-filter">
                  {selSubject}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="Subject">All</Dropdown.Item>
                  {subjects.map((item, index) => 
                      <Dropdown.Item key={index} eventKey={item}>{item}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            : null
          }
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {books.length > 0
            ? <Table responsive className="table-dark table-sm table-hover text-left">
                <caption>{books.length} total entries</caption>
                <thead>
                  <tr>
                    <th className="col-title" scope="col">Title</th>
                    <th className="col-author" scope="col">Author</th>
                    <th className="d-none d-md-table-cell" scope="col">{columns[2]}</th>
                    <th className="d-none d-sm-table-cell" scope="col">Type</th>
                    <th className="d-none d-md-table-cell" scope="col">Pages</th>
                    <th className="d-none d-md-table-cell" scope="col">File</th>
                    <th className="col-subject" scope="col">{columns[5]}</th>
                  </tr>
                </thead>
                <tbody>
                  {books.map((item, index) => 
                    <tr key={index}>
                      <th scope="row">{item['Cost'] === 'Paid' ? <Badge className="mr-2" variant="success">$</Badge> : null}<a href={item['Link']}>{item[columns[0]]}</a></th>
                      <td>{item[columns[1]]}</td>
                      <td className="d-none d-md-table-cell">{item[columns[2]]}</td>
                      <td className="d-none d-sm-table-cell">{item['Type']}</td>
                      <td className="d-none d-md-table-cell">{item[columns[3]]}</td>
                      <td className="d-none d-md-table-cell">{item[columns[4]]}</td>
                      <td>{item[columns[5]]}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            : <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
          }
        </Col>
      </Row>

      <Row className="text-center pt-4">
        <Col>
          <p>© {currentYear} The Library of the Serpent</p>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
